import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPageLayout, AppPageLinks } from 'src/app.consumer/components';
import { Localized, Typography } from 'src/app/components';

const seo: SeoObject = {
  title: 'Terms of business',
};

export const TermsOfBusinessPage = (props: AppProps) => {
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={<Localized dictKey={'staticLocales:pages./terms-of-business.subtitle'} />}
      title={<Localized dictKey={'staticLocales:pages./terms-of-business.link label'} />}
      {...{ ...props, seo }}
    >
      <Typography variant={'body1'}>
        <Localized dictKey={'staticLocales:pages./terms-of-business.content'} />
      </Typography>
    </AppPageLayout>
  );
};

export default TermsOfBusinessPage;
